import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SignUpNBU } from '../../actions/Auth';
import FormGroup from '../../components/FormGroup';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Notification from '../../components/Notification';
import DateOfBirth from './DateOfBirth';

const Register = ({ onChangeModalTarget, metadata = {}, referer }) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [
    { firstName, lastName, username, email, dob, dobDay, dobMonth, dobYear, password, confirmPassword, },
    setState,
  ] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    dob: null,
    dobDay: null,
    dobMonth: null,
    dobYear: null,
    password: '',
    confirmPassword: '',

  });

  const { loading } = useSelector(state => ({
    loading: state.auth.loading,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  const HandleChange = useCallback(
    e => {
      const { name, value } = e.target;

      let cleanValue = value || "";
      if (name === "username") {
        cleanValue = String(value).toLowerCase();
      }

      setState(st => ({ ...st, [name]: cleanValue }));
    },
    [setState]
  );

  // const handleDateChangeRaw = useCallback(e => {
  //   if (e.target.value) {
  //     setState(st => ({ ...st, dob: moment(e.target.value) }));
  //   }
  // }, []);

  const isValid = state => {
    const { firstName, email, lastName, password, confirmPassword /* username, dob */ } = state;
    // const age = dob ? moment().diff(dob, 'years', false) : 0;

    const regexpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let error = ``;

    if (!firstName || firstName.length < 2) {
      error += `first name, `;
    }

    if (!lastName || lastName.length < 2) {
      error += `last name, `;
    }

    // && !child
    if (!RegExp(regexpEmail).test(email.trim())) {
      error += `email, `;
    }

    if (!password || password !== confirmPassword) {
      error += `confirm password, `;
    }

    if (error) {
      window.modal.alert('Please check this fields', error);
    }
    return !error;
  };

  const HandleSubmit = useCallback(
    e => {
      e.preventDefault();
      const data = {
        firstName,
        lastName,
        username,
        email,
        dob,
        password,
      };
      if (
        isValid({
          ...data,
          confirmPassword,
        })
      ) {
        dispatch(SignUpNBU(data, history, referer));
      }
    },
    [dispatch, firstName, lastName, username, email, dob, password, confirmPassword, history, referer]
  );

  const { showDoBField } = metadata;


  return (
    <form autoComplete="nope" onSubmit={HandleSubmit}>
      <FormGroup type="text" name="firstName" placeholder="Name" required onChange={HandleChange} value={firstName} />
      <FormGroup type="text" name="lastName" placeholder="Surname" required onChange={HandleChange} value={lastName} />

      {showDoBField ? (
        <div className="form-group">
          <div className="dp-wrapper">
            {/* <DatePicker
              selected={dobDay}
              required
              placeholderText="Day"
              dateFormat="DD"
              showMonthYearPicker
              maxDate={moment()}
              // onBlur={handleDateChangeRaw}
              onChange={date => HandleChange({ target: { name: 'dobDay', value: date } })}
            />
             */}

            <DateOfBirth onChange={value => HandleChange({ target: { name: 'dob', value: value } })} />
          </div>
        </div>
      ) : null}

      <FormGroup type="email" name="email" placeholder="Email" required onChange={HandleChange} value={email} />
      <FormGroup type="text" name="username" placeholder="Username" required onChange={HandleChange} value={username}>
        <Notification>
          Username must be minimum 2 max 22 characters, only small latin letters, numbers, period symbol (.) and
          underscore symbol (_) allowed
        </Notification>
      </FormGroup>

      <FormGroup
        type="password"
        name="password"
        placeholder="Password"
        required
        onChange={HandleChange}
        value={password}>
        <Notification>Password: minimum 8 max 25 characters, at least one letter and at least one number.</Notification>
      </FormGroup>

      <FormGroup
        type="password"
        required
        name="confirmPassword"
        placeholder="Confirm password"
        onChange={HandleChange}
        value={confirmPassword}
      />
      <div className="agree_checkbox_register">
        <input name="isGoing" type="checkbox" onChange={() => { setIsAccepted(!isAccepted) }} />
        <span> By tapping Register, you agree to the <Link target="_blank" to="/assets/file/terms-of-service">Terms of Service</Link> and <Link to="/assets/file/privacy-policy" target="_blank">Privacy Policy</Link></span>
      </div>

      <div className="signin">
        <button  className='btn py-2 px-4 rounded-pill btn-success' disabled={loading || !isAccepted} type="submit"  >
          Register
        </button>
      </div>

      <div className="forgot-password">
        Already have an account
        <button onClick={() => onChangeModalTarget('login')} type="submit">
          Sign In
        </button>
      </div>
    </form>
  );
};

export default Register;
