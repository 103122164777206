import axios from "../config/axios";
import {
  GET_COURT_ERROR,
  GET_COURT_LISTING_SUCCESS,
  GET_COURT_LOADING,
  GET_COURT_SUCCESS,
  RESET_SINGLE_LISTING,
  MSGS_LOADING,
  MSGS_SUCCESS,
} from "../constants/Court";

function getCortListingSuccess(payload) {
  return {
    type: GET_COURT_LISTING_SUCCESS,
    payload: payload,
  };
}

function getCortSuccess(payload) {
  return {
    type: GET_COURT_SUCCESS,
    payload: payload,
  };
}

function getCortError(payload) {
  return {
    type: GET_COURT_ERROR,
    payload: payload,
  };
}

function resetSingleListing(payload) {
  return {
    type: RESET_SINGLE_LISTING,
    payload: payload,
  };
}

function getCourtLoading(bool) {
  return {
    type: GET_COURT_LOADING,
    payload: bool,
  };
}

function getMSGSLoading(bool) {
  return {
    type: MSGS_LOADING,
    payload: bool,
  };
}

function getMSGSSuccess(payload) {
  return {
    type: MSGS_SUCCESS,
    payload: payload,
  };
}

export function getCourt(cpid, courtid) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      let res = await axios.get(`court/get/${courtid}`);
      dispatch(getCortSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function getCourtListing(clid, courtid) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      let res = await axios.get(`appointment/get/${clid}`);

      dispatch(getCortListingSuccess(res.data.payload));
    } catch (error) {
      dispatch(
        getCortError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function getMSG(options) {
  return async (dispatch) => {
    dispatch(getMSGSLoading(true));
    try {
      let res = await axios.post(`appointment/getmsgs`, {
        ...options,
      });

      dispatch(getMSGSSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getMSGSLoading(false));
    }
  };
}

export function getCourtBusinessListing(data) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      let res = await axios.post(`appointment/businesslisting`, data);

      dispatch(getCortListingSuccess(res.data.payload["0"]));
    } catch (error) {
      dispatch(
        getCortError({
          message: error.message,
        })
      );
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export function cancelCourtBooking(clid, courtid) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      let res = await axios.post("user/court/cancelbookbatch", {
        courtid: courtid,
        clids: [clid],
        note: "",
      });
      if (res.data.code !== 200) throw new Error(res.data.message);
      dispatch(getCortListingSuccess(res.data.payload[0]));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}



export function resetListing() {
  return async (dispatch) => {
    try {
      dispatch(resetSingleListing({}));
    } catch (error) {} finally {}
  };
}

// function time_convert(num) {
//   const hours = num / 60;

//   return hours ? parseFloat(hours) : -1;
// }

export function updateCourt(id, obj, business) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      let blocked_courts =
        obj.blocked_courts.length > 0 ?
        obj.blocked_courts.map((v) => v.id) :
        typeof obj.blocked_courts === "object" &&
        obj.blocked_courts !== null ? [obj.blocked_courts.id] : [];
      const req_body = {
        block_court_vertical_step: obj.block_court_vertical_step,
        is_block_court_vertical: obj.is_block_court_vertical,
        name: obj.name,
        virtual_court: obj.virtual_court.length === 1 ? true : false,
        blocked_courts: [...new Set(blocked_courts)].filter(Boolean),
        price: obj.price,
        booking_to_start: obj.booking_to_start,
        min_list_duration: obj.min_list_duration,
        details: obj.details,
        surface: obj.surface,
        party_size:obj?.party_size,
        max_time_for_singles: obj.max_time_for_singles,
        max_time_for_doubles: obj.max_time_for_doubles,
        max_time_for_triples: obj.max_time_for_triples,
        max_time_for_quadruples: obj.max_time_for_quadruples,


        enable_max_time_for_bookings: obj.enable_max_time_for_bookings,

        time_to_start_booking: obj.time_to_start_booking,
        enable_time_to_start_booking: obj.enable_time_to_start_booking,
      };

      if (
        business.max_time_for_singles &&
        business.max_time_for_doubles &&
        business.max_time_for_triples &&
        business.max_time_for_quadruples &&
        !req_body.enable_max_time_for_bookings &&
        (req_body.min_list_duration > business.max_time_for_singles ||
          req_body.min_list_duration > business.max_time_for_doubles ||
          req_body.min_list_duration > business.max_time_for_triples ||
          req_body.min_list_duration > business.max_time_for_quadruples)
      ) {
        return await window.modal.alert(
          "Error",
          "Minimum and Max time settings are in conflict."
        );
      }

      let res = await axios.put(`court/update/${id}`, {
        ...req_body,
      });

      dispatch(getCortSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}
