import axios from "../config/axios";
import moment from "moment-timezone";
import {
  sendCaptureIntentRequest
} from "./util";
import {
  STRIPE_CONNECT_SUCCESS,
  STRIPE_CONNECT_ERROR,
  STRIPE_GET_PLANS_SUCCESS,
  STRIPE_GET_PLANS_ERROR,
  ADD_PAYMANT_SUCCESS,
  VERIFI_ACCOUNT_SUCCESS,
  VERIFI_ACCOUNT_LOADING,
  CHANGE_PAYMANT_SUCCESS,
  REMOVE_PAYMANT_SUCCESS,
  ADD_PLAN_LOADING,
  ADD_PLAN_SUCCESS,
  UPDATE_PLAN_SUCCESS,
  DELETE_PLAN_SUCCESS,
  GET_PRODUCT_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  ADD_CHARGE_LOADING,
} from "../constants/Stripe";
import {
  GET_SINGLE_LESSON_SUCCESS,
  ADD_LESSON_LOADING,
} from "../constants/Lesson";

import {
  GET_COURT_LOADING,
  GET_COURT_LISTING_SUCCESS,
} from "../constants/Court";
import {
  ADD_PAYMANT_SUCCESS_GROUP
} from "../constants/Group";

import {
  GetMember
} from "./Members";

import {
  addSourciesSuccess,
  changeSourciesSuccess,
  removeCardBusiness,
} from "../actions/Auth";

import {
  addSourciesStaffSuccess,
  changeSourciesStaffSuccess,
  removeCardStaff,
} from "../actions/Coach";

function addChargeLoading(bool) {
  return {
    type: ADD_CHARGE_LOADING,
    payload: bool,
  };
}

/**Single Court part */
function getCortListingSuccess(payload) {
  return {
    type: GET_COURT_LISTING_SUCCESS,
    payload: payload,
  };
}

function getCourtLoading(bool) {
  return {
    type: GET_COURT_LOADING,
    payload: bool,
  };
}

/**** */

function ConnectSuccess(payload) {
  return {
    type: STRIPE_CONNECT_SUCCESS,
    payload: payload,
  };
}
export function ConnectError(error) {
  return {
    type: STRIPE_CONNECT_ERROR,
    payload: error,
  };
}

function addLessonLoading(bool) {
  return {
    type: ADD_LESSON_LOADING,
    payload: bool,
  };
}

function getPlansSuccess(plansList) {
  return {
    type: STRIPE_GET_PLANS_SUCCESS,
    payload: plansList,
  };
}

function addPlanLoading(bool) {
  return {
    type: ADD_PLAN_LOADING,
    payload: bool,
  };
}

function addPlanSuccess(data) {
  return {
    type: ADD_PLAN_SUCCESS,
    payload: data,
  };
}

function updatePlanSuccess(data) {
  return {
    type: UPDATE_PLAN_SUCCESS,
    payload: data,
  };
}

function deletePlanSuccess(data) {
  return {
    type: DELETE_PLAN_SUCCESS,
    payload: data,
  };
}
//Product Part
function addProductSuccess(data) {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload: data,
  };
}

function getProductSuccess(plansList) {
  return {
    type: GET_PRODUCT_SUCCESS,
    payload: plansList,
  };
}

function updateProductSuccess(data) {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: data,
  };
}

function deleteProductSuccess(data) {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: data,
  };
}

export function getPlansError(error) {
  return {
    type: STRIPE_GET_PLANS_ERROR,
    payload: error,
  };
}

function addPaymantSuccess(payload, from) {
  if (from === "MEMBER") {
    return {
      type: ADD_PAYMANT_SUCCESS,
      payload,
    };
  }
  return {
    type: ADD_PAYMANT_SUCCESS_GROUP,
    payload,
  };
}

function changeDefaultCardSuccess(payload, from) {
  return {
    type: CHANGE_PAYMANT_SUCCESS,
    payload,
  };
}

function removeCardSuccess(payload, uid) {
  return {
    type: REMOVE_PAYMANT_SUCCESS,
    payload: {
      ...payload,
      uid: uid,
    },
  };
}

function verifiBankAccountSuccess(uid, payload) {
  return {
    type: VERIFI_ACCOUNT_SUCCESS,
    payload: {
      ...payload,
      uid,
    },
  };
}

function verifiBankAccountLoading(bool) {
  return {
    type: VERIFI_ACCOUNT_LOADING,
    payload: bool,
  };
}

function getSingleLessonsSuccess(payload) {
  return {
    type: GET_SINGLE_LESSON_SUCCESS,
    payload: payload,
  };
}
export function connectstripeaccount(code) {
  return async (dispatch) => {
    try {
      const res = await axios.post("payment/connectstripe", {
        code,
      });
      dispatch(ConnectSuccess(res.data.payload));
      await window.modal.alert("Success", "Success connect");
    } catch (error) {
      dispatch(ConnectError(error));
      await window.modal.alert("Error", error.response.data.message);
    }
  };
}

export function addPlanList(data) {
  return async (dispatch) => {
    try {
      const {
        id,
        error,
        one_time,
        default_tax_rates,
        nbu_subscribe,
        min_age,
        max_age,
        period_end,
        valid_days,
        description,
        facility_groups,
        is_individual,
        ...rest
      } = data;

      let _default_tax_rates = [
        ...default_tax_rates.map((v) => (v.tax_id ? v.tax_id : v)),
      ];
      let _nbu_subscribe = nbu_subscribe === "0" ? false : true;
      let inner_fields = {
        default_tax_rates: _default_tax_rates,
        nbu_subscribe: _nbu_subscribe,
        min_age: min_age || null,
        max_age: max_age || null,
        one_time: one_time,
        description: description || null,
        is_individual: is_individual || false,
        valid_days: valid_days,
        period_end: period_end ?
          moment(period_end)
          .utc()
          .set({
            second: 0,
            millisecond: 0,
          })
          .format() : null,
        facility_groups: facility_groups.map((itm) => itm.id),
      };
      let request_data = {
        ...inner_fields,
        ...rest,
      };
      dispatch(addPlanLoading(true));

      let url = "payment/plan/create";
      if (id) {
        url = `payment/plan/update/${id}`;
        request_data = {
          trial_period_days: data.trial_period_days,
          nickname: data.nickname,
          ...inner_fields,
        };
      }

      const res = await axios.post(url, request_data, {});
      if (id) {
        one_time
          ?
          dispatch(updateProductSuccess(res.data.payload)) :
          dispatch(updatePlanSuccess(res.data.payload));
      } else {
        one_time
          ?
          dispatch(addProductSuccess(res.data.payload)) :
          dispatch(addPlanSuccess(res.data.payload));
      }
    } catch (error) {
      console.log("error: ", error);
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addPlanLoading(false));
    }
  };
}

export function deletePlan(plan_id, one_time = false) {
  return async (dispatch) => {
    try {
      dispatch(addPlanLoading(true));
      let url = `payment/plan/delete/${plan_id}${one_time ? "?product=1" : ""}`;

      const res = await axios.delete(url, {});
      one_time
        ?
        dispatch(deleteProductSuccess(res.data.payload)) :
        dispatch(deletePlanSuccess(res.data.payload));
    } catch (error) {
      console.log(error);
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addPlanLoading(false));
    }
  };
}
export const updateSubAndInitiationOrdering = (data) => async (dispatch) => {
  try {
    await axios.post(`payment/plan/order`, data);
    return true;
  } catch (e) {
    console.log("error: ", e);
  } finally {
  }

  return false;
};
export function getPlanList(one_time) {
  return async (dispatch, getStore) => {
    try {
      if (getStore().stripe.isConnected) {
        dispatch(addPlanLoading(true));
       
        const res = await axios.get(
          `payment/plan/getall${one_time ? "?product=1" : ""}`
        );
        one_time
          ?
          dispatch(getProductSuccess(res.data.payload)) :
          dispatch(getPlansSuccess(res.data.payload));
      }
      
    } catch (error) {
      console.error(error);
      dispatch(getPlansError(error));
      //await window.modal.alert('Error', error.response.data.message);
    } finally {
      dispatch(addPlanLoading(false));
    }
  };
}
export function sendSubscription(uid, planIds, cupon) {
  return async (dispatch) => {
    try {
      dispatch(addPlanLoading(true));
      cupon = cupon ? cupon : null;
      const SubscriptionsPromeses = planIds.map((planId) => {
        return axios.post("payment/subscribe_member", {
          member_id: uid,
          plan: planId,
          coupon: cupon,
        });
      });
      await Promise.all(SubscriptionsPromeses);

      dispatch(GetMember(uid));
      dispatch(addPlanLoading(false));
      await window.modal.alert("Success", "Success subscribe");
    } catch (error) {
      dispatch(addPlanLoading(false));
      //dispatch(subscriptionError(error))
      await window.modal.alert("Error", error.response.data.message);
    }
  };
}

export function cancelsubscription(uid, subscriptionIds) {
  return async (dispatch) => {
    try {
      dispatch(addPlanLoading(true));
      const SubscriptionsPromeses = subscriptionIds.map((subscription) => {
        return axios.post("payment/cancelsubscription", {
          member_id: uid,
          subscription_id: subscription,
        });
      });
      const res = await Promise.all(SubscriptionsPromeses);
      if (!res.every((item) => item.data.code === 200))
        throw new Error(res.data.message);
      dispatch(GetMember(uid));
      dispatch(addPlanLoading(false));
      await window.modal.alert("Success", "Success remove subscriptions");
    } catch (error) {
      dispatch(addPlanLoading(false));
      //dispatch(subscriptionError(error))
      await window.modal.alert("Error", error.response.data.message);
    }
  };
}
export function updateIndividuals(uid, individuals) {
  return async (dispatch) => {
    try {
      await axios.post("payment/updateindividual", {
        member_id: uid,
        individuals: individuals,
      });
      dispatch(GetMember(uid));
      await window.modal.alert("Success", "Success");
    } catch (error) {
      //dispatch(subscriptionError(error))
      await window.modal.alert("Error", error.response.data.message);
    }
  };
}
export function updateSelectedtaxes(uid, taxes) {
  return async (dispatch) => {
    try {
      await axios.post("payment/updatetaxes", {
        member_id: uid,
        taxes: taxes,
      });
      dispatch(GetMember(uid));
      await window.modal.alert("Success", "Success");
    } catch (error) {
      //dispatch(subscriptionError(error))
      await window.modal.alert("Error", error.response.data.message);
    }
  };
}

export function addPaymant(uid, token, admin = false, user_id, options = {}) {
  return async (dispatch) => {
    try {
      let from = "MEMBER";
      let _url = admin ?
        "payment/createforbusienssmember" :
        "payment/createformember";
      const res = await axios.post(_url, {
        member_id: user_id,
        token,
      });

      let is_staff = options.is_staff ? true : false;
      if (is_staff) {
        dispatch(
          addSourciesStaffSuccess({
            payment: res.data.payload,
            uid: uid,
          })
        );
      }

      if (!is_staff) {
        if (admin) {
          dispatch(addSourciesSuccess(res.data.payload));
        } else {
          dispatch(
            addPaymantSuccess({
                payment: res.data.payload,
                uid: uid,
              },
              from
            )
          );
        }
      }

      //await window.modal.alert('Success', 'Card added success');
    } catch (error) {
      console.log(error);
      //dispatch(subscriptionError(error))
      //await window.modal.alert('Error', error.response.data.message);
    }
  };
}
export function subscribeNbu(params = {}) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`users/nbu/subscribe`, {
        ...params,
      });
      return true;
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
      return false;
    }
  };
}

export function changeDefaultCard(
  uid,
  cardId,
  from = "MEMBER",
  admin = false,
  options = {}
) {
  return async (dispatch) => {
    try {
      //setdefaultpayment
      let _url = admin ?
        "payment/setdefaultpaymentadmin" :
        "payment/setdefaultpayment";
      const res = await axios.post(_url, {
        member_id: uid,
        token: cardId,
      });
      let is_staff = options.is_staff ? true : false;
      if (is_staff) {
        dispatch(changeSourciesStaffSuccess(res.data.payload));
      }
      if (!is_staff) {
        if (admin) {
          dispatch(changeSourciesSuccess(res.data.payload));
        } else {
          dispatch(changeDefaultCardSuccess(res.data.payload, from));
        }
      }

      await window.modal.alert("Success", "Default Card saved success");
    } catch (error) {
      console.log(error);
      //dispatch(subscriptionError(error))
      // await window.modal.alert('Error', error.response.data.message);
    }
  };
}
export function removeCard(member_id, cardId, admin = false, options = {},remove_payment_account_id) {
 
  return async (dispatch) => {
    try {
      //setdefaultpayment
      let _url = admin ?
        "members/delete_card" :
        `members/delete_card/${member_id}`;
      const res = await axios.post(_url, {
        card: cardId,
        remove_payment_account_id: remove_payment_account_id,
      });

      let is_staff = options.is_staff ? true : false;
      if (is_staff) {
        dispatch(
          removeCardStaff({
            ...res.data.payload,
            uid: options.user_id,
          })
        );
      }

      if (!is_staff) {
        if (admin) {
          dispatch(removeCardBusiness(res.data.payload));
        } else {
          dispatch(removeCardSuccess(res.data.payload, member_id));
        }
      }

      await window.modal.alert("Success", "Card removed success");
    } catch (error) {
      //dispatch(subscriptionError(error))
      await window.modal.alert("Error", error.response.data.message);
    }
  };
}

export function verifiBankAccount(uid, bankAccountSourceId, amount1, amount2) {
  return async (dispatch) => {
    try {
      dispatch(verifiBankAccountLoading(true));
      const res = await axios.post("payment/verifybankaccount", {
        member_id: uid,
        bank_source_id: bankAccountSourceId,
        amount1,
        amount2,
      });

      dispatch(verifiBankAccountSuccess(uid, res.data.payload));
      return true
      //await window.modal.alert('Success', 'Card added success');
    } catch (error) {
      //dispatch(subscriptionError(error))
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(verifiBankAccountLoading(false));
    }
  };
}

export async function chargecustomer({
  amount,
  token,
  description,
  currency,
  uid = null,
  child_id = null,
  goods = true,
  name = null,
  balance = false,
  selected_taxes = [],
  invoice = false,
}) {
  try {
    let requestData = {
      amount,
      token,
      description,
      currency,
      balance,
      member_id: uid,
      child_id: child_id,
      full_name: name,
      invoice: invoice,
    };
    if (!goods) {
      requestData = {
        ...requestData,
        tax: true,
        selected_taxes: selected_taxes.map((v) => v.tax_id),
      };
    }
    const res = await axios.post("payment/charges/create", requestData, {});
    return res;
  } catch (error) {
    throw error;
  }
}

export function setStateAfterCustomerBookAndCharge(payload) {
  return async (dispatch) => {
    dispatch(getSingleLessonsSuccess(payload));
  };
}
export function setStateAfterCustomerBookAndChargeForCourt(payload) {
  return async (dispatch) => {
    dispatch(getCortListingSuccess(payload));
  };
}

export async function chargeCustomerForLesson(
  uid,
  lessonState,
  stripeState,
  member_id
) {
  try {
    const requestData = mapRequestDataForCharge(
      uid,
      lessonState,
      stripeState,
      member_id
    );
    const res = await axios.post(
      "payment/charges/createforappointment",
      requestData, {}
    );

    if (res.data.code !== 200) throw new Error(res.data.message);
    return res.data.payload;
  } catch (error) {
    throw error;
  }
}

export function chargeCustomerWithCashInput(data) {
  return async (dispatch) => {
    dispatch(addChargeLoading(true));
    try {
      const charge_for = data.charge_for;
      const res = await axios.post(
        "payment/charges/createforcashinput",
        data, {}
      );

      if (res.data.code !== 200) {
        throw new Error(res.data.message);
      }
      switch (charge_for) {
        case "lesson":
          dispatch(getSingleLessonsSuccess(res.data.payload));
          break;
        case "court":
          dispatch(getCortListingSuccess(res.data.payload));
          break;

        default:
          break;
      }
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addChargeLoading(false));
    }
  };
}

export function chargeCustomerWithPoint(data) {
  return async (dispatch) => {
    dispatch(addChargeLoading(true));
    try {
      const charge_for = data.charge_for;
      const res = await axios.post("payment/charges/createforpoint", data, {});

      if (res.data.code !== 200) {
        throw new Error(res.data.message);
      }
      switch (charge_for) {
        case "lesson":
          dispatch(getSingleLessonsSuccess(res.data.payload));
          break;
        case "court":
          dispatch(getCortListingSuccess(res.data.payload));
          break;

        default:
          break;
      }
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addChargeLoading(false));
    }
  };
}

export function captureTerminalStripe(payment_intent_id, data) {
  return async (dispatch) => {
    try {
      const res = await sendCaptureIntentRequest(payment_intent_id, {
        ...data,
      });
      const charge_for = data.charge_for;
      switch (charge_for) {
        case "lesson":
          dispatch(getSingleLessonsSuccess(res));
          break;
        case "court":
          dispatch(getCortListingSuccess(res));
          break;

        default:
          break;
      }
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {}
  };
}

export async function chargeCustomerForCourt(
  uid,
  courtState,
  stripeState,
  member_id
) {
  try {
    const requestData = mapRequestDataForCharge(
      uid, {
        ...courtState,
        charge_for: "court",
        llid: courtState.clid,
        sport_id: courtState.sport_id,
      },
      stripeState,
      member_id
    );
    const res = await axios.post(
      "payment/charges/createforappointment",
      requestData, {}
    );

    return res.data["payload"];
  } catch (error) {
    throw error;
  }
}

export async function getPrice(options = {}) {
  try {
    const res = await axios.post("appointment/price", options, {});

    return Number.parseFloat(res.data["payload"]).toFixed(2);
  } catch (error) {
    throw error;
  }
}

export async function getCoachAvailabilityPrice(options = {}) {
  try {
    const {
      data: {
        payload
      },
    } = await axios.post("appointment/price-coach", options, {});

    const main_value = Number.parseFloat(payload.price).toFixed(2);
    const point_value = Number.parseFloat(payload.point_price).toFixed(2);
    return {
      main_value,
      point_value,
    };
  } catch (error) {
    throw error;
  }
}

export function bookCustomerForLesson(uid, lessonState) {
  return async (dispatch) => {
    dispatch(addLessonLoading(true));
    try {
      const res = await axios.post(`appointment/book_for/${lessonState.llid}`, {
        userId: uid,
        all: lessonState.all,
        fullName: lessonState.userFullName,
        book_for: "lesson",
      });

      dispatch(getSingleLessonsSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}
export function bookCustomerForLessonNew(uid, data) {
  return async (dispatch) => {
    dispatch(addLessonLoading(true));
    try {
      const {
        appointment_id,
        booking_type,
        timezone,
        userFullName,
        ...rest
      } = data;

      let request_data = {
        userId: uid,
        fullName: userFullName,
        appointment_id: appointment_id,
        booking_type: booking_type,
      };

      if (data.payment) {
        let payment = {
          method: data.payment.method,
          amount: data.payment.amount,
          description: data.payment.description,
          currency: data.payment.currency,
        };
        if (data.payment.customer) {
          payment = {
            ...payment,
            customer: data.payment.customer,
          };
        }
        if (data.payment.token) {
          payment = {
            ...payment,
            token: data.payment.token,
          };
          delete payment.customer;
        }
        request_data = {
          ...request_data,
          payment: {
            ...payment,
          },
        };
      }

      if (data.booking_type === "range") {
        request_data.dates = [{
          startDate: moment(data.startDate)
            .tz(timezone)
            .startOf("day")
            .clone()
            .utc()
            .format(),
          endDate: moment(data.endDate)
            .tz(timezone)
            .add(1, "day").startOf("day")
            .clone()
            .utc()
            .format(),
        }, ];
      }

      const res = await axios.post(`lesson/book/${data.lesson_id}/`, {
        ...request_data,
      });

      dispatch(getSingleLessonsSuccess(res.data.payload));
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
    } finally {
      dispatch(addLessonLoading(false));
    }
  };
}
export function bookCustomerForCourt(uid, courtState, stripeState) {
  return async (dispatch) => {
    dispatch(getCourtLoading(true));
    try {
      const res = await axios.post(`appointment/book_for/${courtState.clid}`, {
        userId: uid,
        all: courtState.all,
        // sport_id: courtState.sport_id,
        fullName: courtState.userFullName,
        add_on_id: courtState.add_on_id,
        book_for: "court",
        partners: courtState.partners ? courtState.partners.map((v) => v.id) : [],
        guests: courtState.guests ? courtState.guests.map((v) => v.name) : [],
      });
      dispatch(getCortListingSuccess(res.data.payload));
      // return res.data['payload']['0'];
    } catch (error) {
      await window.modal.alert("Error", error.response.data.message);
     
    } finally {
      dispatch(getCourtLoading(false));
    }
  };
}

export async function createStripeCardReader(code, lable,locationId) {
  try {
    const res = await axios.post(
      "payment/reader/create", {
        registration_code: code,
        label: lable,
        location: locationId,
      }, {}
    );
    return res;
  } catch (error) {
    throw error;
  }
}

function mapRequestDataForCharge(
  uid = null,
  lessonState,
  stripeState,
  member_id
) {
 
  let data = {
    save_card:stripeState.save_card || false,
    invoice: stripeState.invoice || false,
    appointment_id: lessonState.llid, // lesson listing id
    amount: stripeState.price || null, //stripeState.6price, // amount to charge for both "booking"  and "charge"
    token: uid ? null : stripeState.stoken ? stripeState.stoken : null, // visitor or user payment token or null to use club member default payment method if mid is member uid
    description: stripeState.note || "",
    currency: stripeState.currency ? stripeState.currency : "usd",
    charge_for: lessonState.charge_for || "lesson",
    child_id: lessonState.child_id ? lessonState.child_id : null,
    member_id: member_id ? member_id : uid, // member or visitor uid ("visitor-bb66e98e-866e-4483-8208-9c83c9b7dcbd") or null for first time visitor
    full_name: stripeState.name ?
      stripeState.name : lessonState.userFullName ?
      lessonState.userFullName : "", // name on card TODO NEED TO DISCUSS WITH BACKEND
  };

  if (!stripeState.goods) {
    data = {
      ...data,
      tax: true,
      selected_taxes: stripeState.selected_taxes.map((v) => v.tax_id),
    };
  }

  data.member_id = data.member_id || member_id;

  return data;
}
